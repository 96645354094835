import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomImageGallery from '../components/CustomImageGallery';

const leftArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" /></svg>

const SingleNews = () => {
    const params = useParams();
    const { news } = useSelector((state) => state);
	const navigate = useNavigate();

    const selectedNews = news.data.find((item) => item.id == params.id);

	// console.log(selectedNews)

    return (
        <div className="container px-4 mx-auto flex-col mt-12">
			<div className='mx-0 md:mx-8 lg:mx-16'>
				<div className='flex'>
					{leftArrow}
					<h2 className='cursor-pointer' onClick={() => navigate(-1)}> YZA</h2>
				</div>
				<div className='top-8 right-2 w-16 h-1 bg-primary'></div>
			</div>
            <div className="flex-col text-center">
                <h3 className="text-2xl md:text-4xl md:font-semibold">{selectedNews.title}</h3>
            </div>
			<div className='mt-4 flex-row lg:flex mx-0 md:mx-8 lg:mx-16'>
				<div className='mt-0 lg:w-2/3'>
					{/* <img className='rounded shadow-xl' src={`${selectedNews.image}`} alt={selectedNews.title} /> */}
					<CustomImageGallery images={selectedNews.news_image} />
				</div>
				<div className='mt-4 lg:mt-0 lg:w-1/3 lg:ml-8'>
					<h3 className='text-justify'>{selectedNews.content}</h3>
					<h4 className='mt-4 text-gray-700 font-semibold'>{selectedNews.created_at} ý</h4>
					{selectedNews.video_file && (
						<div className='mt-8 rounded overflow-hidden flex justify-center'>
							<video className='' controls>
								<source src={`${selectedNews.video_file}`} type="video/mp4" />
							</video>
						</div>
					)}
				</div>
			</div>

        </div>
    );
};

export default SingleNews;
