import React from 'react';
import { useSelector } from 'react-redux';
import Gollanma from '../components/gollanma';

const GollanmaPage = () => {
	const {gollanma} = useSelector((state) => state)
	return (
		<div>
			<Gollanma gollanma={gollanma.data} />
		</div>
	)
}

export default GollanmaPage
