import axios from "axios";

export const getAllData = () => async (dispatch) => {
	try {
		dispatch({type: 'DATA_GET_REQUEST'});
		const config = {
			headers: {
				'content-type': 'application/json',
			},
		}
		const {data} = await axios.get(`/api/company-info/`, config);

		dispatch({
			type: 'DATA_GET_SUCCESS',
			payload: data,
		})
	} catch (error){
		dispatch({
			type: 'DATA_GET_ERROR',
			payload: error.error
		})
	}
}

export const getAllNews = () => async(dispatch) => {
	try{
		dispatch({type: 'DATA_NEWS_REQUEST'});
		const config = {
			headers: {
				'content-type': 'application/json',
			},
		}
		const {data} = await axios.get(`/api/all-news/`, config);
		dispatch({
			type: 'DATA_NEWS_SUCCESS',
			payload: data,
		})
	} catch (error){
		dispatch({
			type: 'DATA_NEWS_ERROR',
			payload: error.error
		})
	}
}

export const getAllBooks = () => async(dispatch) => {
	try{
		dispatch({type: 'DATA_BOOKS_REQUEST'});
		const config = {
			headers: {
				'content-type': 'application/json',
			},
		}
		const {data} = await axios.get(`/api/all-books/`, config);
		dispatch({
			type: 'DATA_BOOKS_SUCCESS',
			payload: data,
		})
	} catch (error){
		dispatch({
			type: 'DATA_BOOKS_ERROR',
			payload: error.error
		})
	}
}

export const getAllGollanma = () => async(dispatch) => {
	try{
		dispatch({type: 'DATA_GOLLANMA_REQUEST'});
		const config = {
			headers: {
				'content-type': 'application/json',
			},
		}
		const {data} = await axios.get(`/api/all-gollanma/`, config);
		dispatch({
			type: 'DATA_GOLLANMA_SUCCESS',
			payload: data,
		})
	} catch (error){
		dispatch({
			type: 'DATA_GOLLANMA_ERROR',
			payload: error.error
		})
	}
}

export const getAllImages = () => async(dispatch) => {
	try{
		dispatch({type: 'DATA_IMAGES_REQUEST'});
		const config = {
			headers: {
				'content-type': 'application/json',
			},
		}
		const {data} = await axios.get(`/api/all-images/`, config);
		dispatch({
			type: 'DATA_IMAGES_SUCCESS',
			payload: data,
		})
	} catch (error){
		dispatch({
			type: 'DATA_IMAGES_ERROR',
			payload: error.error
		})
	}
}

