import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import Gerb from './images/gerb_png.png'

const menuIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-6 text-white " fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" /></svg>
const homeIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
const newsIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" /></svg>
const booksIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" /></svg>
const imagesIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>

const Nav = ({companyName}) => {
	const [menuOpen, setMenuOpen] = useState(false)

	return (
		<header className='sticky py-4 z-50 bg-primary flex top-0 shadow-xl rounded-md mx-1 md:mx-2 my-1'>
			<div className='relative container flex justify-between items-center mx-auto pl-8 md:pl-14 lg:pl-24 w-full'>
				<div className='flex justify-center items-center'>
					<img className='w-8 h-8' src={Gerb} />
					<Link to="/" className='cursor-pointer text-base md:text-lg py-1 px-2 rounded-full uppercase mr-4 text-white'>{companyName}</Link>
				</div>
				{/* <div className='hidden  w-2/3 md:flex md:space-x-4 lg:space-x-12 items-center justify-center content-center rounded-lg text-lg shadow-lg py-2 bg-gray-50'> */}
				<div className='hidden  md:w-2/3 md:flex md:space-x-4 lg:space-x-12 items-center justify-center content-center rounded-lg text-lg'>
					<Link to="/" className='hover:bg-white text-white hover:text-gray-800 rounded-lg flex py-1 px-2'>{homeIcon} Baş sahypa</Link>
					<Link to="/news" className='hover:bg-white text-white hover:text-gray-800 rounded-lg flex py-1 px-2'>{newsIcon} Täzelikler</Link>
					<Link to="/books" className='hover:bg-white text-white hover:text-gray-800 rounded-lg flex py-1 px-2'>{booksIcon} Kitaplar</Link>
					<Link to="/gollanmalar" className='hover:bg-white text-white hover:text-gray-800 rounded-lg flex py-1 px-2'>{booksIcon} Gollanmalar</Link>
					<Link to="/gallery" className='hover:bg-white text-white hover:text-gray-800 rounded-lg flex py-1 px-2'>{imagesIcon} Suratlar</Link>
					{/* <Link to="/contacts" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-xl'>Salgymyz</Link> */}
				</div>
				<div className='md:hidden cursor-pointer mr-2' onClick={() => setMenuOpen(!menuOpen)}>
					{menuIcon}
					{menuOpen && (
						<div className='absolute flex flex-col w-48 right-6 bg-slate-50 bg-opacity-90 text-lg rounded-lg mt-2 shadow-lg h-40 justify-around'>
							<Link to="/" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-sm flex'>{homeIcon} Baş sahypa</Link>
							<Link to="/news" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-sm flex'>{newsIcon} Täzelikler</Link>
							<Link to="/books" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-sm flex'>{booksIcon} Kitaplar</Link>
							<Link to="/gollanmalar" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-sm flex'>{booksIcon} Gollanmalar</Link>
							<Link to="/gallery" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-sm flex'>{imagesIcon} Suratlar</Link>
							{/* <Link to="/contacts" className='hover:bg-primary hover:text-gray-100 py-0 px-3 rounded-sm'>Salgymyz</Link> */}
						</div>
					)}
				</div>
			</div>
		</header>
	)
}

export default Nav
