import React from 'react';
import GollanmaComponent from './GollanmaComponent';

const gollanma = ({gollanma}) => {
	console.log(gollanma)
	return (
		<div className="container max-w-6xl mx-auto m-8">
			<h2 className='w-full my-2 text-2xl md:text-4xl font-bold leading-tight text-center text-gray-800'>Gollanmalar</h2>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-10  w-full  px-4 lg:px-0'>
				{gollanma && gollanma.map((goll) => (
					<div key={goll.id}>
						<GollanmaComponent gollanmaItem={goll} />
					</div>
				))}
			</div>
		</div>
	)
}

export default gollanma
