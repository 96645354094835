import React from 'react'
import { useSelector } from 'react-redux';
import Books from '../components/books'

const BooksPage = () => {
	const {books} = useSelector((state) => state)
	// console.log(books)
	return (
		<div>
			<Books books={books.data} />
		</div>
	)
}

export default BooksPage
