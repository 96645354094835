import React from 'react'

import Carousel from 'nuka-carousel';

const carousel = ({banners}) => {
	return (
		<Carousel className='rounded-lg overflow-hidden object-contain' withoutControls={true} autoplayInterval={3000} autoplay={true}>
			{banners && banners.map((banner) => (
				<img key={banner.id} src={`${banner.image}`} alt={`${banner.image_alt}`}/>
			))}
		</Carousel>
	)
}

export default carousel
