import React from 'react';
import Carousel from '../components/carousel';
import About from '../components/about';
import News from '../components/news';
import { Link } from 'react-router-dom';
import Books from '../components/books';
import CarouselComponent from '../components/CarouselComponent';
import CustomParticles from '../components/CustomParticles';
import Gatlar from '../components/gatlar';
import Gollanma from '../components/gollanma';

const rightArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6 mt-2 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>

const HomePage = ({data}) => {
	return (
		<div className='m-2 md:m-4'>
			{/* <CustomParticles /> */}
			<div className='mt-8 rounded-sm max-w-7xl mx-auto z-10'>
				<Carousel banners={data.banners} />
			</div>
			<div className='flex justify-center items-center mt-8 z-10'>
				<About about={data.aboutus[0]} gatlar={data.gatlar} />
			</div>
			<div>
				<News news={data.news} />
				<div className='flex mr-12 md:mr-48 mt-8 relative justify-end z-20'>
					<Link to="news/" className='text-gray-900 py-1 px-2 rounded-md ml-2'>Ählisini gör</Link>
					{rightArrow}
					<div className='absolute top-8 right-2 w-24 h-1 bg-primary'></div>
				</div>
			</div>
			<div className='z-30'>
				<Books books={data.books} />
				<div className='flex mr-12 lg:mr-48 mt-8 relative justify-end'>
					<Link to="books/" className='text-gray-900 py-1 px-2 rounded-md ml-2'>Ählisini gör</Link>
					{rightArrow}
					<div className='absolute top-8 right-2 w-24 h-1 bg-primary'></div>
				</div>
			</div>
			<div className='z-30'>
				<Gollanma gollanma={data.gollanma} />
				<div className='flex mr-12 lg:mr-48 mt-8 relative justify-end'>
					<Link to="gollanmalar/" className='text-gray-900 py-1 px-2 rounded-md ml-2'>Ählisini gör</Link>
					{rightArrow}
					<div className='absolute top-8 right-2 w-24 h-1 bg-primary'></div>
				</div>
			</div>
		</div>
	)
}

export default HomePage
