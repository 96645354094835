import React from 'react'
import { Link } from 'react-router-dom'

const rightArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-8 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>

const NewsComponent = ({newsItem}) => {

	return (
		<div className="max-w-lg mx-auto flex justify-center mb-4">
			<div className="">
				<div className='relative w-80 md:w-60'>
					<div className='absolute -right-4 -bottom-3 bg-primary w-full h-full rounded-xl'></div>
					<div className='relative bg-gray-800 text-gray-50 rounded-xl p-4 space-y-4'>
						<div className='text-xl font-bold '>
							{newsItem.title}
						</div>
						<div className='h-2 w-24 bg-primary'></div>
						<p className='leading-snug text-gray-400'>{newsItem.content.substring(0, 84)}</p>
						<div className='flex'>
							{/* <Link data={newsItem} to="/singlenews" className='block text-gray-50 font-thin tracking-widest text-sm'>Giňişleýin</Link> */}
							<Link to={`/singlenews/${newsItem.id}`} className='block text-gray-50 font-thin tracking-widest text-sm'>Giňişleýin</Link>
							{rightArrow}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewsComponent