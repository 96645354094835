import React from 'react'
import BooksComponent from './BooksComponent'

const Books = ({books}) => {
	return (
		<div className="container max-w-6xl mx-auto m-8">
			<h2 className='w-full my-2 text-2xl md:text-4xl font-bold leading-tight text-center text-gray-800'>Kitaplar</h2>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-10  w-full  px-4 lg:px-0'>
				{books && books.map((book) => (
					<div key={book.id}>
						<BooksComponent bookItem={book} />
					</div>
				))}
			</div>
		</div>
	)
}

export default Books
