export const dataGetReducer = (state = {data: [], loading: true}, action) => {
	switch (action.type){
		case 'DATA_GET_REQUEST':
			return {
				loading: true,
				data: [],
			};
		case 'DATA_GET_SUCCESS':
			return {
				loading: false,
				data: action.payload,
			}
		case 'DATA_GET_ERROR':
			return {
				loading: false,
				error: action.payload
			}

		default:
			return state;
	}
}

export const dataGetNews = (state = {data:[], loading: true}, action) => {
	switch (action.type){
		case 'DATA_NEWS_REQUEST':
			return {
				loading: true,
				data: [],
			}
		case 'DATA_NEWS_SUCCESS':
			return {
				loading: false,
				data: action.payload
			}
		case 'DATA_NEWS_ERROR':
			return {
				loading: false,
				error: action.payload
			}
		default:
			return state
	}
}

export const dataGetBooks = (state = {data:[], loading: true}, action) => {
	switch (action.type){
		case 'DATA_BOOKS_REQUEST':
			return {
				loading: true,
				data: [],
			}
		case 'DATA_BOOKS_SUCCESS':
			return {
				loading: false,
				data: action.payload
			}
		case 'DATA_BOOKS_ERROR':
			return {
				loading: false,
				error: action.payload
			}
		default:
			return state
	}
}
export const dataGetGollanma = (state = {data:[], loading: true}, action) => {
	switch (action.type){
		case 'DATA_GOLLANMA_REQUEST':
			return {
				loading: true,
				data: [],
			}
		case 'DATA_GOLLANMA_SUCCESS':
			return {
				loading: false,
				data: action.payload
			}
		case 'DATA_GOLLANMA_ERROR':
			return {
				loading: false,
				error: action.payload
			}
		default:
			return state
	}
}

export const dataGetImages = (state = {data:[], loading: true}, action) => {
	switch (action.type){
		case 'DATA_IMAGES_REQUEST':
			return {
				loading: true,
				data: [],
			}
		case 'DATA_IMAGES_SUCCESS':
			return {
				loading: false,
				data: action.payload
			}
		case 'DATA_IMAGES_ERROR':
			return {
				loading: false,
				error: action.payload
			}
		default:
			return state
	}
}

