import React, { useState } from 'react'

const rightArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-8 mt-1 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
const leftArriw = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-8 mt-1 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" /></svg>

const About = ({about, gatlar}) => {
	const [open, setOpen] = useState(true)
	const [content, setContent] = useState(gatlar[0])

	return (
		<div className='border-b py-8 z-20 bg-stone-50 rounded-md px-0 md:px-24'>
			<div className='container max-w-5xl mx-auto m-8 relative'>
				<h1 className="w-full my-2 text-2xl md:text-4xl font-bold leading-tight text-center text-gray-800">{about.title}</h1>
				<div className='absolute w-32 h-2 bg-primary left-1/2'></div>
				<div className="w-full mb-4">
					<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
				</div>
				<div className='flex flex-wrap'>
					<div className='w-full md:w-1/2 py-6'>
						<p className='text-gray-600 mb-8 text-lg text-justify'>{about.content}</p>
					</div>
					<div className='w-full md:w-1/2 px-4'>
						<div className='mt-8 rounded overflow-hidden'>
							{/* <img className='shadow-2xl rounded-md' src={about.image} alt="" /> */}
							<video className='' controls>
								<source src={`${about.video_file}`} type="video/mp4" />
							</video>
						</div>
					</div>
					<div onClick={() => setOpen(!open)} className='cursor-pointer ml-8 text-white mt-6 md:mt-0'>
						{open ? (
							<div className='bg-primary px-2 rounded-xl w-28 flex justify-center py-1'>
								<h2 className='flex'>{leftArriw} Gizle</h2>
								<div className='w-0 h-1 transition bg-white delay-100 duration-300 ease-in-out hover:w-24'></div>
							</div>
						) : (
							<div className='bg-primary px-2 rounded-xl w-28 flex justify-center py-1'>
								<h2 className='flex'>Görkez {rightArrow}</h2>
								<div className='w-0 h-1 transition bg-white delay-100 duration-300 ease-in-out hover:w-24'></div>
							</div>
						)}

					</div>
				</div>
			</div>
			{open && (
				<div>
					<div className='flex justify-center mb-6'><h2 className='w-full my-2 text-2xl md:text-4xl font-bold leading-tight text-center text-gray-800'>Gatlar</h2></div>
					<div className='md:flex max-w-6xl'>
						<div className='flex md:flex-col w-full md:w-1/5'>
							{gatlar && gatlar.map((gat) => (
								// <div key={gat.id} className='cursor-pointer grid justify-items-center mr-3 md:flex items-center mb-8 border-b-2 border-l-2 border-primary rounded-2xl py-1 px-4 hover:bg-primary' onClick={() => setContent(gat)}>
								<div key={gat.id} className='cursor-pointer grid text-gray-800 hover:text-white justify-items-center mr-3 md:flex items-center mb-8 transition delay-100 duration-300 ease-in-out border-b-4 border-l-4 border-gray-500 hover:border-gray-400 rounded-2xl py-1 px-4 hover:bg-gray-500' onClick={() => setContent(gat)}>
									<img className='w-12 ml-4 md:ml-0 md:w-14 mr-4 z-10' src={gat.icon} />
									<h2 className='-rotate-90 md:rotate-0 text-xl md:text-2xl text-justify'>{gat.icon_title}</h2>
								</div>
							))}
						</div>
						<div className='md:ml-8 w-full md:w-4/5 md:flex'>
							<div className='w-full flex-col'>
								<h2 className='text-xl uppercase mx-4 md:mx-0'>{content.title}</h2>
								<div className='h-1 w-24 my-2 bg-primary mx-4 md:mx-0'></div>
								<div className='w-full'>
									<img className='shadow-xl rounded-md shadow-gray-400 w-96 float-left mr-4 mb-2' src={`${content.image}`} />
									<p className='text-justify mx-4 md:mx-0'>{content.content}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default About
