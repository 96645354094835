import {createStore, combineReducers, applyMiddleware} from 'redux'

import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension'

import {dataGetReducer, dataGetNews, dataGetBooks, dataGetImages, dataGetGollanma} from './reducers'

const reducer = combineReducers({
	info: dataGetReducer,
	news: dataGetNews,
	books: dataGetBooks,
	images: dataGetImages,
	gollanma: dataGetGollanma,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store