import React from 'react'

const ContactsPage = () => {
	return (
		<div>
			<h3>Contacts</h3>
			{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6323.444496162632!2d61.8377209!3d37.5851576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f418fd0c8a5916f%3A0xb3adfbf358572a5e!2z0JzQsNGA0YvQudGB0LrQsNGPINCy0LXQu9Cw0Y_RgtGB0LrQsNGPINCx0LjQsdC70LjQvtGC0LXQutCw!5e0!3m2!1sru!2sru!4v1640258527729!5m2!1sru!2sru" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
		</div>
	)
}

export default ContactsPage
