import React from 'react'

import NewsComponent from './NewsComponent'


const News = ({news}) => {
	return (
		<div className="container max-w-6xl mx-auto m-8">
			<h2 className='w-full my-2 text-2xl md:text-4xl font-bold leading-tight text-center text-gray-800'>Täzelikler we Çäreler</h2>

			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-10 justify-center items-center w-full'>
				{news && news.map((item) => (
					<div key={item.id}>
						<NewsComponent newsItem={item} />
					</div>
				))}
			</div>
		</div>
	)
}

export default News