import React from 'react'
import { Link } from 'react-router-dom'

const rightArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-8 mt-1 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
const GollanmaComponent = ({gollanmaItem}) => {
	return (
		<div className='bg-gray-200 rounded-lg z-auto'>
			<div>
				<img className='shadow-2xl rounded-lg' src={`${gollanmaItem.image}`} alt='' />
			</div>
			<div className='mt-2 flex justify-center'>
				<h2 className='text-xl text-gray-900 font-semibold uppercase'>{gollanmaItem.title.substring(0, 30)} ...</h2>
			</div>
			<div className='mt-2 flex justify-center'>
				<h3 className='text-slate-700'>Taýýarlan: <span className='text-lg text-gray-900 font-semibold'>{gollanmaItem.author}</span></h3>
			</div>
			{/* <div className='mt-2 px-2'>
				<p className='leading-snug text-justify'>{gollanmaItem.description.substring(0, 124)} ...</p>
			</div> */}
			<div className='flex py-4 justify-end'>
				<Link to={`/SingleGollanma/${gollanmaItem.id}`} className='block text-gray-700 tracking-widest text-sm'>Giňişleýin</Link>
				{rightArrow}
			</div>
		</div>
	)
}

export default GollanmaComponent
