import React from 'react';
import { useSelector } from 'react-redux';


const GalleryPage = () => {
	const {images} = useSelector((state) => state);
	const image_list = images.data
	return (
		<div className='container flex-col mx-auto'>
			<h3 className='text-center my-6 text-4xl'>Surat Gallereýasy</h3>
			<div className='gallery flex-col'>
				{image_list && image_list.map((image, index) => (
					<div key={index} className='grid-item shadow-md'>
						<img src={image.image} alt={`${image.image_alt}`} />
					</div>
				))}
			</div>
		</div>
	)
}

export default GalleryPage
