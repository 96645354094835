import React from 'react'
import { Link } from 'react-router-dom'

const Footer = ({data}) => {
	return (
		<div className='bg-gray-700 bottom-0 w-full absolute z-20'>
			<div className='grid grid-cols-1 md:grid-cols-4 py-4 justify-center items-center '>
				<div className='flex-col justify-center mb-4 md:mb-0'>
					<h3 className='text-gray-300 text-xl text-center'>{data.title}</h3>
				</div>
				<div className='mt-4'>
					<h3 className='text-center text-gray-200'>Salgymyz: {data.address1}</h3>
					{/* <h3 className='text-center text-gray-200'>{data.address1}</h3> */}
					<h3 className='text-center text-gray-200'>{data.address2}</h3>
				</div>
				<div className='flex-col items-center'>
					<h3 className='text-gray-300 text-center'>TEL: {data.phone1}</h3>
					{/* <h3 className='text-gray-300 text-center'>TEL: {data.phone2}</h3> */}
					<h3 className='text-gray-300 text-center'>FAX: {data.fax}</h3>
				</div>
				<div>
					<h3 className='text-gray-300 text-center'>{data.email}</h3>
				</div>
				{/* <div className='mt-4 grid-cols-1 justify-center'>
				</div> */}
			</div>
			<div className='grid grid-cols-2 md:grid-cols-6 mt-4 mb-8'>
				<div className='hidden md:block'></div>
				<Link to="/" className='text-gray-200 underline-offset-4 hover:bg-primary rounded-lg text-center mx-4 w-32 border-l-2 border-b-2 border-primary shadow-xl'>Baş sahypa</Link>
				<Link to="/news" className='text-gray-200  underline-offset-4 hover:bg-primary rounded-lg text-center mx-4 w-32 border-l-2 border-b-2 border-primary shadow-xl'>Täzelikler</Link>
				<Link to="/books" className='text-gray-200 mt-2 md:mt-0  underline-offset-4 hover:bg-primary rounded-lg text-center mx-4 w-32 border-l-2 border-b-2 border-primary shadow-xl'>Kitaplar</Link>
				<Link to="/gollanmalar" className='text-gray-200 mt-2 md:mt-0  underline-offset-4 hover:bg-primary rounded-lg text-center mx-4 w-32 border-l-2 border-b-2 border-primary shadow-xl'>Gollanmalar</Link>
				{/* <Link to="/contacts" className='text-gray-200  underline-offset-4 hover:bg-primary rounded-lg text-center mx-4 w-32 border-l-2 border-b-2 border-primary shadow-xl'>Suratlar</Link> */}
				<Link to="/gallery" className='text-gray-200 mt-2 md:mt-0 underline-offset-4 hover:bg-primary rounded-lg text-center mx-4 w-32 border-l-2 border-b-2 border-primary shadow-xl'>Suratlar</Link>
				<div></div>
			</div>
		</div>
	)
}

export default Footer
