import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loader from "react-loader-spinner";

import Nav from './components/nav2';
import HomePage from './pages/HomePage';
import NewsPage from './pages/NewsPage';
import BooksPage from './pages/BooksPage';
import ContactsPage from './pages/ContactsPage';
import SingleNews from './pages/SingleNews';
import SingleBook from './pages/SingleBook';
import GalleryPage from './pages/GalleryPage';
import ComingSoonPage from './pages/ComingSoonPage';
import GollanmaPage from './pages/GollanmaPage';
import SingleGollanma from './pages/SingleGollanma';

import CustomParticles from './components/CustomParticles';

import {getAllData, getAllNews, getAllBooks, getAllImages, getAllGollanma} from './store/actions'
import Footer from './components/footer';


function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllData());
        dispatch(getAllNews());
        dispatch(getAllBooks());
        dispatch(getAllImages());
        dispatch(getAllGollanma());
    }, [dispatch])

    const {info} = useSelector((state) => state)

    return (
        <Router>
            <div className='relative bg-light-color text-dark-color min-h-screen bg-gradient-to-bl from-gray-50 via-white to-pink-50'>
                {/* <CustomParticles /> */}
                {info.loading ? (
                    <div className='w-screen h-screen flex justify-center items-center'>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={200}
                            width={200}
                        />
                    </div>
                ) : (
                    <>
                        <Nav companyName={info.data.company.title} />
                        <div className='pb-96 md:pb-64'>
                            <Routes>
                                <Route exact path="/" element={<HomePage data={info.data} />} />
                                <Route path="/news" element={<NewsPage/>} />
                                <Route path="/books" element={<BooksPage/>} />
                                <Route path="/gollanmalar" element={<GollanmaPage/>} />
                                <Route path="/contacts" element={<ContactsPage/>} />
                                <Route path="/gallery" element={<GalleryPage/>} />
                                <Route path="/singlenews/:id" element={<SingleNews/>}/>
                                <Route path="/singlebook/:id" element={<SingleBook />} />
                                <Route path="/singlegollanma/:id" element={<SingleGollanma />} />
                            </Routes>
                        </div>
                        <div className='pt-8 md:pt-0'>
                            <Footer data={info.data.company} />
                        </div>
                    </>
                )}
            </div>
        </Router>
    );
}

export default App;
