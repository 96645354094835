import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const leftArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-6 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" /></svg>

const SingleGollanma = () => {
	const params = useParams();
	const {gollanma} = useSelector((state) => state);
	// console.log(books)
	const navigate = useNavigate();
	const selectedGollanma = gollanma.data.find((item) => item.id == params.id)
	return (
		<div className='container px-4 max-w-4xl mx-auto mt-8'>
			<div className='mx-0 md:mx-8 lg:mx-16'>
				<div className='flex'>
					{leftArrow}
					<h2 className='cursor-pointer' onClick={() => navigate(-1)}> YZA</h2>
				</div>
				<div className='top-8 right-2 w-16 h-1 bg-primary'></div>
			</div>

			<div className='lg:flex mt-12'>
				<div className='w-full flex md:w-1/2'>
					<img className='rounded-lg' src={`${selectedGollanma.image}`} />
				</div>
				<div className='w-full flex-col md:w-1/2 md:pl-8 px-3 md:px-2'>
					<h2 className='text-lg'>Ady: <span className='text-2xl uppercase'>{selectedGollanma.title}</span></h2>
					<h3 className='text-lg mt-4'>Taýýarlan: <span className='text-2xl'>{selectedGollanma.author}</span></h3>
					{/* <h3 className='text-lg mt-4'>ISBN: <span className='text-2xl'>{selectedBook.ISBN}</span></h3> */}
					{/* <h3 className='text-lg mt-4'>Çap edilen senesi: <span className='text-2xl'>{selectedGollanma.published_at}</span></h3> */}
					<p className='text-justify text-lg mt-4 underline font-semibold'>Gollanma Barada:</p>
					<p className='text-justify text-xl'>{selectedGollanma.description}</p>
				</div>
			</div>
		</div>
	)
}

export default SingleGollanma
