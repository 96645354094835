import React from 'react'
import { useSelector } from 'react-redux';
import News from '../components/news';


const NewsPage = () => {
	const {news} = useSelector((state) => state)
	return (
		<div className='m-2 md:m-4'>
			<News news={news.data} />
		</div>
	)
}

export default NewsPage
